<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone, debounce} from 'lodash'

export default {
  components: {
    ListHeader,
    FormSaveButton,
    FormCancelButton
  },
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingClient: false,
      searchClient: null,
      searchClientModel: null,
      searchMergeClient: null,
      searchMergeClientModel: null,
      form: {
        client_id: null,
        clients: []
      },
      dependencies: {
        clients: [],
        clientsMerge: []
      }
    }
  },
  validations: {
    form: {
      client_id: {required},
      clients: {required}
    }
  },
  mounted() {
    bus.$emit('hide-loader')
  },
  watch: {
    'searchClient': debounce(function (value) {
      this.onSearchClient(value)
    }, 300),
    'searchMergeClient': debounce(function (value) {
      value && value !== this.searchMergeClientModel && this.onSearchMergeClient(value)
    }, 300)
  },
  computed: {
    clientsMergeList() {
      return this.dependencies.clientsMerge.filter(item => {
        if (this.form.clients.length) {
          return !this.form.clients.some(client => client.id === item.id)
        }
        return true
      })
    }
  },
  methods: {
    onSearchClient(query) {
      this.isSearchingClient = true
      let data = {query, only_clients: true}
      restfulService.post('client', 'autocomplete', null, data)
          .then((response) => {
            if (response.length > 0) {
              this.dependencies.clients = [...response]
            }
            this.isSearchingClient = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSearchMergeClient(query) {
      this.isSearchingClient = true
      let data = {query, only_clients: true}
      restfulService.post('client', 'autocomplete', null, data)
          .then((response) => {
            if (response.length > 0) {
              if (this.form.client_id) {
                response = [...response.filter(item => item.id !== this.form.client_id)]
              }
              this.dependencies.clientsMerge = [...response]
            }
            this.isSearchingClient = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSelectClient() {
      if (this.searchMergeClientModel) {
        const client = this.dependencies.clientsMerge.find(item => item.id === this.searchMergeClientModel)
        this.form.clients.push(client)
        setTimeout(() => {
          this.searchMergeClientModel = null
        }, 100)
      }
    },
    removeClient(item) {
      this.form.clients = this.form.clients.filter(client => client.id !== item.id)
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      restfulService.save('client', 'merge', null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'client.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Mesclar Clientes"/>
    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados dos Clientes</span>
      </div>
      <el-alert type="error"
                show-icon
                :closable="false"
                size=""
                class="mb-3"
                center>
        <div slot="title" class="text-center">
          <div class="fs-18 font-weight-bold">
            Tenha muito cuidado ao realizar essa ação!
          </div>
          <div class="mt-2 fs-16 pl-3">
            Ao confirmar a mesclagem entre os clientes, TODAS as referências do sistema como comandas, reservas,
            lançamentos, inscrições, planos e etc serão mescladas no cadastro principal.
          </div>
        </div>
      </el-alert>
      <el-form ref="form"
               class="mt-2"
               :model="form"
               label-position="top">
        <div class="highlight-card -payment">
          <h3 class="card-title">Cliente (Esse cadastro será o principal, os selecionados serão mesclados a ele)</h3>
          <el-form-item>
            <v-autocomplete v-model="form.client_id"
                            :items="dependencies.clients"
                            :search-input.sync="searchClient"
                            clearable
                            no-data-text="Buscando por Nome/Celular/Email/CPF"
                            color="blue-grey lighten-2"
                            label="Nome do Cliente"
                            item-text="name"
                            item-value="id"
                            hide-details
                            hide-selected
                            no-filter>
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <v-list-tile-title>{{ data.item.name }}</v-list-tile-title>
                  <v-list-tile-sub-title v-if="data.item.phone">
                    {{ data.item.phone | phone }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </template>
            </v-autocomplete>
          </el-form-item>
        </div>
        <el-row :gutter="20" class="p-2 mt-4">
          <el-col :span="12">
            <h3 class="card-title">Clientes que serão mesclados</h3>
            <el-form-item>
              <v-autocomplete v-model="searchMergeClientModel"
                              :items="clientsMergeList"
                              :search-input.sync="searchMergeClient"
                              @change="onSelectClient"
                              clearable
                              no-data-text="Buscando por Nome/Celular/Email/CPF"
                              color="blue-grey lighten-2"
                              label="Nome do Cliente"
                              item-text="name"
                              item-value="id"
                              hide-details
                              hide-selected>
                <template slot="selection" slot-scope="data">
                  ({{ data.item.id }}) {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-tile-content>
                    <v-list-tile-title>({{ data.item.id }}) {{ data.item.name }}</v-list-tile-title>
                    <v-list-tile-sub-title v-if="data.item.phone">
                      {{ data.item.phone | phone }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <h3>Clientes Selecionados</h3>
            <v-list two-line>
              <template v-for="(item, index) in form.clients">
                <v-list-tile :key="item.id">
                  <v-list-tile-content>
                    <v-list-tile-title>({{ item.id }}) {{ item.name }}</v-list-tile-title>
                    <v-list-tile-sub-title v-if="item.phone">
                      {{ item.phone | phone }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn icon ripple @click="removeClient(item)">
                      <i class="fas fa-times" color="error"></i>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider :key="index + 1"/>
              </template>
            </v-list>
          </el-col>
        </el-row>

        <el-form-item class="mt-4">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="client.index"/>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
