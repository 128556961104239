<script>
import UserInfo from '../../domains/system/user/components/UserInfo'

import * as restfulService from '../../services/restfulService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import {get} from '@utils/storage'
import {mapGetters, mapActions} from 'vuex'
import {orderBy} from 'lodash'

import {isMobile} from 'mobile-device-detect'
import NotificationFullModal from "../../domains/system/user/components/NotificationFullModal.vue";

export default {
  name: 'app-header',
  components: {NotificationFullModal, UserInfo},
  mixins: [helpers],
  data() {
    return {
      tenantBlocked: false,
      isSubmitted: false,
      showNotifications: false,
      toggle: true,
      notifications: [],
      notification: {
        notification: {}
      },
      notificationTenantAnnouncement: {
        show: false,
        data: {}
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'showSidebar', 'tenant']),
    isMobile() {
      return isMobile
    },
    notificationsNew() {
      return this.notifications.filter(item => item.sent_at && !item.read_at)
    },
    notificationsNotOpened() {
      return orderBy(this.notifications.filter(item => !item.filed_at), ['sent_at'], ['desc'])
    },
    websocketKey() {
      return 'newTenantNotification'
    },
    websocketChannelName() {
      return `${this.websocketKey}.${this.tenant.id}.${this.currentUser.id}`
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    }
  },
  watch: {
    showNotifications: function (value) {
      if (value && this.notificationsNew.length) {
        this.readNotifications()
      }
    },
    notificationsNotOpened: {
      deep: true,
      immediate: true,
      handler(value) {
        this.notificationTenantAnnouncement.show = false
        this.notificationTenantAnnouncement.data = {}
        const notification = value.find(item => item.read_at === null && item.message_key === 'tenant_announcement_update')
        if (notification) {
          this.notificationTenantAnnouncement.data = {...notification}
          this.notificationTenantAnnouncement.show = true
        }
      }
    }
  },
  async mounted() {
    this.tenantBlocked = await get('blocked')
    this.startWebsocket()
    this.getNotifications()
  },
  beforeDestroy() {
    window.Echo.leaveChannel(this.websocketChannelName)
  },
  methods: {
    ...mapActions(['logout', 'toggleSidebar']),
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, ({notification}) => {
            this.toggle = false
            this.notifications = [...this.notifications.filter(item => item.id !== notification.id)]
            this.notifications.unshift(notification)
            setTimeout(() => {
              this.toggle = true
            }, 100)
          })
    },
    onLogout() {
      bus.$emit('show-loader')
      restfulService.post('auth', 'logout')
          .then(() => {
            this.logout()
                .then(() => {
                  this.$router.push({name: 'auth.login'})
                })
          })
          .catch(e => {
            console.log(e)
          })
    },
    getNotifications() {
      this.isLoadingData = true
      this.notifications.splice(0)
      restfulService.post('user', 'notifications')
          .then(response => {
            if (response.length) {
              this.notifications = [...response]
            }
            this.isLoadingData = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async readNotifications() {
      const data = {
        notifications_id: this.notificationsNew.map(item => item.id),
        status: 'read'
      }
      await restfulService.post('user', 'notification/update', null, data)
      this.notificationsNew.map(item => {
        item.read_at = 'now'
        return item
      })
    },
    async notificationAction(notification) {
      const listNotification = this.notifications.find(item => item.id === notification.id)
      const listNotificationIndex = this.notifications.findIndex(item => item.id === notification.id)
      if (listNotification) {
        listNotification.status = 'filed'
        listNotification.filed_at = 'now'
        this.notifications.splice(listNotificationIndex, 1, listNotification)
      }
      const data = {
        notifications_id: [notification.id],
        status: 'filed'
      }
      await restfulService.post('user', 'notification/update', null, data)
    },
    notificationLink(notification) {
      this.$router.push({name: notification.data.extra.route.name, params: notification.data.extra.route.params})
      this.showNotifications = !this.showNotifications
      if (this.$router.currentRoute.name === notification.data.extra.route.name) {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    },
    handleUserInfoModal() {
      bus.$emit('show-loader')
      bus.$emit('open-modal-user', true)
    },
    goToNotifications() {
      this.showNotifications = !this.showNotifications
      this.$router.push({name: 'user.notifications'})
    }
  }
}
</script>

<template>
  <div>
    <notification-full-modal :notification="notificationTenantAnnouncement.data"
                             :show="notificationTenantAnnouncement.show"
                             v-if="notificationTenantAnnouncement.show"
                             @reloadNotifications="getNotifications"/>

    <v-toolbar color="white"
               fixed
               app
               dense
               clipped-right
               class="app-header-toolbar">
      <v-toolbar-side-icon @click.stop="toggleSidebar(!showSidebar)"></v-toolbar-side-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y
                left
                :nudge-right="0"
                v-model="showNotifications"
                :close-on-content-click="false"
                :max-width="isMobile ? '96vw' : '450px'"
                :min-width="isMobile ? '96vw' : '450px'">
          <template v-slot:activator="{ on }">
            <v-btn icon
                   :disabled="tenantBlocked"
                   class="header-user-notifications mr-3"
                   v-on="on">
              <v-badge color="green darken-2" v-model="toggle">
                <template v-slot:badge v-if="notificationsNew.length > 0">
                  {{ notificationsNew.length }}
                </template>
                <span class="icon-notification"><i class="fas fa-bell"></i></span>
              </v-badge>
            </v-btn>
          </template>
          <v-card max-height="400">
            <v-list dense class="notifications-list" two-line v-if="notificationsNotOpened.length">
              <template v-for="(item, index) in notificationsNotOpened">
                <v-list-tile :key="item.id" ripple>
                  <v-list-tile-action class="item__action"
                                      v-if="item.data && item.data.extra && item.data.extra.route"
                                      title="Ir para o Link">
                    <v-btn icon color="grey" dark class="v-btn__mini"
                           @click="notificationLink(item)">
                      <i class="fas fa-external-link-alt"></i>
                    </v-btn>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <div v-if="item.title">{{ item.title }}</div>
                    <template v-if="item.message">
                      <template v-if="item.message_key !== 'tenant_announcement_update'">
                        {{ item.message }}
                      </template>
                      <template v-else>
                        <notification-full-modal :notification="item"/>
                      </template>
                    </template>
                  </v-list-tile-content>
                  <v-list-tile-action class="item__info" :title="item.sent_at | dateTimeEnToBr">
                    <v-list-tile-action-text>
                      {{ item.sent_at | dayMonthEnToBr }} {{ item.sent_at | timeEnToBr }}h
                    </v-list-tile-action-text>
                    <v-btn fab depressed small dark color="error"
                           title="Remover notificação"
                           class="v-btn__mini"
                           @click="notificationAction(item)">
                      <i class="fas fa-times"></i>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider :key="index * 500 + 1" v-if="index + 1 < notificationsNotOpened.length"/>
              </template>
            </v-list>
            <v-card-text v-if="!notificationsNotOpened.length">
              Não existem notificações
            </v-card-text>
            <v-card-actions color="grey" class="grey lighten-3 notifications-actions">
              <v-spacer></v-spacer>
              <v-btn @click="goToNotifications">
                <i class="fas fa-bell"></i>
                <span class="ml-2">Ver todas notificações</span>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-menu offset-y left>
          <template v-slot:activator="{ on }">
            <v-btn flat v-on="on">
              <span class="mr-2">{{ currentUser.name }}</span><i class="fas fa-angle-down"></i>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile @click="handleUserInfoModal()" :disabled="tenantBlocked">
              <v-list-tile-title>
                <i class="fas fa-user"></i>
                <span class="ml-2">Meus dados</span>
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="onLogout">
              <v-list-tile-title>
                <i class="fas fa-times-circle"></i>
                <span class="ml-2">Sair</span>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <user-info></user-info>
  </div>
</template>
