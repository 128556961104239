<script>
import FormSaveButton from '@components/form/SaveButton'
import Certificate from "./Certificate.vue";

import * as notifyService from '@app/services/notifyService'
import DataService from '@app/services/dataService'
import {defaultTenantNf, getTenantSettings, saveTenantSettings} from '../../../service'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'
import {required} from "vuelidate/lib/validators";

const SETTINGS_TYPE = 'tenant_nf'

export default {
  components: {Certificate, FormSaveButton},
  name: 'financial',
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      form: {...defaultTenantNf},
      dependencies: {
        nf_regime_tributario: [],
        nf_regime_especial_tributacao: [],
      },
      showPassword: false,
      nfeTab: 'nfse',
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasProfessionalPlan']),
    isCountryBrazil() {
      return this.tenant.country_id === 1
    },
    canUseNf() {
      return this.isCountryBrazil
    },
    hasBasicPlan() {
      return (this.tenant?.modules?.basic ?? false)
    },
    hasProfessionalPlan() {
      return (this.tenant?.modules?.professional ?? false)
    },
    hasCertificate() {
      return this.form.certificate
          && (this.form.certificate.status === 'active' || this.form.certificate.expired === true)
    },
    hasExpiredCertificate() {
      return this.form.certificate
          && (this.form.certificate.status === 'expired' || this.form.certificate.expired === true)
    },
    tenantNfceEnabled() {
      return this.form?.nfce?.enabled || false
    },
    canEnableNfse() {
      return this.form?.nfse_city_enabled
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigCodigoCnaeObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_cnae_obrigatorio_nfse ?? false
    },
    nfseCityConfigItemListaServicoObrigatorioNfse() {
      return this.nfseCityConfig?.item_lista_servico_obrigatorio_nfse ?? true
    },
    nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_tributario_municipio_obrigatorio_nfse ?? false
    }
  },
  validations: {
    form: {
      nfce: {
        inscricao_estadual: {required},
        regime_tributario: {required},
        token_csc: {required},
        proximo_numero: {required}
      },
      nfse: {
        inscricao_municipal: {required},
        regime_especial_tributacao: {required},
        proximo_numero: {required}
      }
    }
  },
  async mounted() {
    this.isComponentLoading = true
    await this.getDependencies()
    this.getSettings()
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'nf_regime_tributario'},
          {domain: 'nf_regime_especial_tributacao'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
        })
        resolve()
      })
    },
    onUpdateCertificate() {
      bus.$emit('hide-loader')
      this.isComponentLoading = true
      bus.$emit('reload-session-info', async () => {
        await this.getSettings()
      })
    },
    async getSettings() {
      const result = await getTenantSettings(SETTINGS_TYPE)
      this.form = {...this.form, ...result.data}
      setTimeout(() => {
        this.nfeTab = this.tenantNfceEnabled ? 'nfce' : 'nfse'
        this.isFirstLoad = false
        this.isComponentLoading = false
      }, 200)
    },
    onSaveSettings(nfType) {
      this.isComponentLoading = true
      const data = {
        type: SETTINGS_TYPE,
        settings: {
          nf_type: nfType
        }
      }
      data.settings[nfType] = {...this.form[nfType]}
      if (nfType === 'nfce') {
        data.settings['nfce_staging'] = {...this.form.nfce_staging}
      }
      saveTenantSettings(data, true)
          .then((settings) => {
            notifyService.success()
            this.form = {...this.form, ...settings.data}
            this.isComponentLoading = false
          })
          .catch(e => {
            this.isComponentLoading = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados..."
           v-if="canUseNf">
    <div slot="header">
      <span>Notas Fiscais</span>
      <div v-if="!hasBasicPlan && !hasProfessionalPlan">
        <el-alert type="error"
                  show-icon
                  :closable="false"
                  class="ml-1"
                  center>
          <div slot="title">
            Disponível no plano de gestão básico ou profissional
          </div>
        </el-alert>
      </div>
    </div>
    <div :class="{'disabled': !hasBasicPlan && !hasProfessionalPlan}">
      <certificate :certificate.sync="form.certificate"
                   @reloadData="onUpdateCertificate"/>
      <el-tabs v-model="nfeTab" stretch :class="{'disabled': hasExpiredCertificate}">
        <el-tab-pane name="nfse" :disabled="!hasBasicPlan && !hasProfessionalPlan">
          <div slot="label">
            <span class="mr-1">Configurar NFSe</span>
            <el-tag type="info" size="mini" v-if="!hasBasicPlan && !hasProfessionalPlan">Indisponível</el-tag>
            <el-tag type="success" size="mini" effect="dark"
                    v-else-if="canEnableNfse && form.nfse.status === 'active' && form.nfse.enabled">
              Ativa
            </el-tag>
            <el-tag type="danger" size="mini" v-else>Inativa</el-tag>
          </div>
          <div>
            <template v-if="!canEnableNfse">
              <el-alert type="warning"
                        show-icon
                        :closable="false">
                <div slot="title" class="fs-16">
                  Seu município não está habilitado para emissão de NFSe, entre em contato
                  com nosso suporte para solicitar a habilitação
                </div>
              </el-alert>
            </template>
            <template
                v-if="(!form.nfse.status || form.nfse.status === 'inactive') && !hasCertificate">
              <el-alert type="warning"
                        show-icon
                        :closable="false">
                <div slot="title" class="fs-16">
                  Para habilitar a emissão de <strong>NFSe</strong> você precisa primeiro enviar o certificado
                  digital!
                </div>
              </el-alert>
            </template>
            <template v-if="hasCertificate && canEnableNfse">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Habilitar NFSe">
                    <el-switch v-model="form.nfse.enabled"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="false">
                  <el-form-item label="Ambiente de Homologação/Testes">
                    <el-switch v-model="form.nfse.staging"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Regime Especial Tributação"
                                class="is-required">
                    <el-select v-model="form.nfse.regime_especial_tributacao"
                               clearable
                               filterable
                               placeholder="Selecione um Regime Tributário"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option v-for="item in dependencies.nf_regime_especial_tributacao"
                                 :key="item.id"
                                 :label="`(${item.id}) ${item.name}`"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Inscrição Municipal"
                                class="is-required">
                    <el-input v-model="form.nfse.inscricao_municipal"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Série do RPS"
                                class="is-required">
                    <el-input v-model="form.nfse.serie_nfse"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Próximo Número para Emissão (RPS)"
                                class="is-required">
                    <el-input v-model="form.nfse.proximo_numero"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Login (Opcional)">
                    <el-input v-model="form.nfse.login"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Senha (Opcional)">
                    <el-input v-model="form.nfse.password"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider class="mt-2 mb-3"/>
              <h4>Dados de Referência ao Criar Serviços</h4>
              <el-row :gutter="20">
                <el-col :span="8" v-if="nfseCityConfigCodigoCnaeObrigatorioNfse">
                  <el-form-item label="Código Cnae">
                    <el-input v-model="form.nfse.codigo_cnae"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="nfseCityConfigItemListaServicoObrigatorioNfse">
                  <el-form-item label="Item da Lista de Serviço">
                    <el-input v-model="form.nfse.item_lista_servico"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse">
                  <el-form-item label="Código Tributário do Município">
                    <el-input v-model="form.nfse.codigo_tributario_municipio"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="ISS Retido">
                    <el-switch v-model="form.nfse.iss_retido"
                               active-color="#13ce66"
                               :active-value="true"
                               active-text="SIM"
                               inactive-color="#ff4949"
                               :inactive-value="false"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Aliquota (%)">
                    <el-input v-model="form.nfse.aliquota"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <form-save-button :event-click="() => onSaveSettings('nfse')"
                                text="Salvar Configurações de NFSe"
                                submitting-text="Salvando Configurações de NFSe"
                                :is-disabled="isComponentLoading || $v.form.nfse.$invalid"
                                :is-submitted="isSubmitted"/>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane name="nfce" :disabled="hasBasicPlan">
          <div slot="label">
            <span class="mr-1">Configurar NFCe</span>
            <el-tag type="info" size="mini" v-if="!hasProfessionalPlan">Indisponível</el-tag>
            <el-tag type="success" size="mini" effect="dark"
                    v-else-if="form.nfce.status === 'active' && form.nfce.enabled">
              Ativa
            </el-tag>
            <el-tag type="danger" size="mini" v-else>Inativa</el-tag>
          </div>
          <div>
            <template
                v-if="(!form.nfce.status || form.nfce.status === 'inactive') && !hasCertificate">
              <el-alert type="warning"
                        show-icon
                        :closable="false">
                <div slot="title" class="fs-16">
                  Para habilitar a emissão de <strong>NFCe</strong> você precisa primeiro enviar o certificado
                  digital!
                </div>
              </el-alert>
            </template>
            <template v-if="hasCertificate">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Habilitar NFCe (Produção)">
                    <el-switch v-model="form.nfce.enabled"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Regime Tributário"
                                class="is-required">
                    <el-select v-model="form.nfce.regime_tributario"
                               clearable
                               filterable
                               placeholder="Selecione um Regime Tributário"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option v-for="item in dependencies.nf_regime_tributario"
                                 :key="item.id"
                                 :label="`(${item.id}) ${item.name}`"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Inscrição Estadual"
                                class="is-required">
                    <el-input v-model="form.nfce.inscricao_estadual"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="ID Token CSC"
                                class="is-required">
                    <el-input v-model="form.nfce.id_token_nfce"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Série Token CSC"
                                class="is-required">
                    <el-input v-model="form.nfce.serie_nfce"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Token CSC (NFCe Produção)"
                                class="is-required">
                    <el-input v-model="form.nfce.token_csc"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Próximo Número para Emissão"
                                class="is-required">
                    <el-input v-model="form.nfce.proximo_numero"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="Configurar Ambiente de Homologação">
                <el-switch v-model="form.nfce_staging.enabled"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="SIM"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
              <template v-if="form.nfce_staging.enabled">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="ID Token CSC"
                                  class="is-required">
                      <el-input v-model="form.nfce_staging.id_token_nfce"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Série Token CSC"
                                  class="is-required">
                      <el-input v-model="form.nfce_staging.serie_nfce"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Token CSC (NFCe Homologação)"
                                  class="is-required">
                      <el-input v-model="form.nfce_staging.token_csc"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Próximo Número para Emissão"
                                  class="is-required">
                      <el-input v-model="form.nfce_staging.proximo_numero"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
              <form-save-button :event-click="() => onSaveSettings('nfce')"
                                text="Salvar Configurações de NFCe"
                                submitting-text="Salvando Configurações de NFCe"
                                :is-disabled="isComponentLoading || $v.form.nfce.$invalid"
                                :is-submitted="isSubmitted"/>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-card>
</template>
