<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import StageStatus from './StageStatus'
import StageType from './StageType'
import StageBracketSystem from './StageBracketSystem'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import {mapGetters} from 'vuex'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import * as fileService from '@app/services/fileService'
import {confirmDialog} from '@utils/flash'

const defaultOrderSearch = {orderBy: [{column: 'created_at', direction: 'DESC'}]}
const defaultDataSearch = {status: 'all'}

export default {
  mixins: [helpers],
  components: {ListMain, ListHeader, ListEditButton, StageStatus, StageType, StageBracketSystem},
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      pdfBase64: '',
      showModalPdf: false,
      filters: {
        circuit_id: '',
        ...defaultDataSearch
      },
      dependencies: {
        stage_status: [],
        circuit: [],
        sports: [],
      }
    }
  },
  mounted() {
    this.getDependencies()
    const data = {...defaultOrderSearch}
    bus.$emit('list-init', {domain: 'stage', relations: ['city', 'circuit', 'dates'], data}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'roles'])
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'stage_status'}, {domain: 'circuit'}, {domain: 'sport'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    onFilter() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {'like': '%' + this.filters.name + '%'}
      }
      if (this.filters.circuit_id) {
        searchData.circuit_id = this.filters.circuit_id
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {...defaultDataSearch}
      bus.$emit('list-filter-clean', this.filters, {...defaultOrderSearch})
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    async duplicateStage(stage) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente duplicar esse torneio?',
        icon: 'warning',
      })
      if (confirm) {
        this.isSubmitted = true
        bus.$emit('show-loader')
        restfulService.post('stage', 'duplicate', stage.id)
            .then(response => {
              notifyService.success({title: response.message})
              this.$router.push({name: 'stage.update', params: {id: response.id}})
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    async tenantPayment(stage) {
      this.isSubmitted = true
      bus.$emit('show-loader')
      restfulService.post('stage', 'tenant-payment', stage.id)
          .then(response => {
            this.pdfBase64 = response.base64
            this.showModalPdf = true
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    exportRanking(item) {
      bus.$emit('show-loader')
      const data = {ranking: 'all'}
      fileService.get('pdf/stage', 'ranking', item.id, 'pdf', data, false, 'Ranking')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    showDates(dates) {
      if (Array.isArray(dates)) {
        if (dates.length > 6) {
          dates = [dates[0], dates[1], '...', dates[dates.length - 2], dates[dates.length - 1]]
        }
        return dates.map(date => date.hasOwnProperty('date') ? this.dateFormat(date.date, 'br') : date).join(', ')
      }
      return ''
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Torneios" route-name="stage.store"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name" placeholder="Filtrar por nome"/>
            </el-form-item>
            <el-form-item label="Circuito">
              <el-select v-model="filters.circuit_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Circuito"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.circuits"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         filterable
                         clearable
                         placeholder="Filtrar por Status"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.stage_status"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData" class="table-responsive">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th class="text-center">Tipo</th>
            <th>Nome</th>
            <th>Circuito</th>
            <th>Cidade/Estado</th>
            <th>Datas</th>
            <th class="text-center">Chaveamento</th>
            <th class="text-center">Status</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td class="text-center">
              <stage-type :type="item.type" :sports="dependencies.sports"/>
            </td>
            <td>{{ item.name }}</td>
            <td>
              <template v-if="!!item.circuit_id">
                <span title="Circuito Externo" v-if="item.circuit && item.circuit.is_external">
                  <i class="fas fa-external-link-alt"></i>
                </span>
                {{ item.circuit ? item.circuit.name : '' }}
              </template>
              <template v-else-if="!!item.external_circuit_reference && item?.metadata?.external_circuit?.name">
                <span title="Circuito Externo">
                  <i class="fas fa-external-link-alt"></i>
                </span>
                {{ item?.metadata?.external_circuit?.name || '' }}
              </template>
            </td>
            <td>{{ item.city ? item.city.name_with_state : '' }}</td>
            <td>{{ showDates(item.dates) }}</td>
            <td class="text-center">
              <stage-bracket-system :bracket-system="item.settings?.bracket_system"/>
            </td>
            <td class="text-center">
              <stage-status :status="item.status"/>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <router-link :to="{ name: 'stage.show', params: { id: item.id } }"
                             class="el-button el-button--default btn-actions-text"
                             title="Gerenciar Torneio">
                  <i class="fas fa-cog"></i>
                  <span class="ml-1">Gerenciar</span>
                </router-link>
                <el-button type="warning"
                           size="small"
                           @click="duplicateStage(item)"
                           :disabled="isSubmitted"
                           title="Duplicar Torneio">
                  <i class="fas fa-clone"></i>
                </el-button>
                <template v-if="item.status !== 'discarded'">
                  <el-button type="primary"
                             size="small"
                             v-if="item.status === 'finished'"
                             @click="exportRanking(item)"
                             :disabled="isSubmitted"
                             title="Gerar Ranking">
                    <i class="fas fa-file-pdf"></i>
                  </el-button>
                  <ListEditButton route-name="stage.update" :item="item"/>
                </template>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>

    <v-dialog v-model="showModalPdf" width="80%">
      <v-card class="modal-pdf">
        <v-card-text>
          <iframe :src="pdfBase64"></iframe>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="showModalPdf = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
