<script>
import ListMain from '@components/list/Main'
import ListExport from '@components/list/Export'
import GripoDialog from '@components/common/Dialog'
import RegistrationStatus from './components/Status'
import RegistrationPayment from './components/Payment'
import RegistrationForm from './Form'

import DataService from '@app/services/dataService'
import * as fileService from '@app/services/fileService'
import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'
import {cloneDeep} from 'lodash'
import {confirmDialog} from '@utils/flash'

const defaultSort = {
  orderBy: [{column: 'id', direction: 'desc'}]
}
const defaultFilters = {
  stage_id: '',
  status: ['created', 'approved'],
  transaction_status: '',
  type: 'all',
  manual_approval: 'all',
  transaction_code: ''
}

export default {
  components: {
    ListExport,
    RegistrationPayment,
    ListMain,
    RegistrationStatus,
    RegistrationForm,
    GripoDialog
  },
  props: ['stageId'],
  data() {
    return {
      isLoadingData: true,
      filters: {...cloneDeep(defaultFilters)},
      selectedList: [],
      dependencies: {
        stages: [],
        players_class: [],
        registration_status: [],
        transaction_status: []
      },
      pdfBase64String: '',
      exportFields: [
        {field: 'id', label: 'ID'},
        {field: 'stage', label: 'Torneio'},
        {field: 'player_class', label: 'Categoria'},
        {field: 'player', label: 'Atleta'},
        {field: 'cpf', label: 'CPF'},
        {field: 'email', label: 'E-mail'},
        {field: 'phone', label: 'Telefone'},
        {field: 'city', label: 'Cidade/Estado/Província'},
        {field: 'created_at', label: 'Data da Inscrição'},
        {field: 'shirt', label: 'Camiseta'},
        {field: 'points', label: 'Pontos'},
        {field: 'round', label: 'Posição'},
        {field: 'type', label: 'Tipo'},
        {field: 'status', label: 'Status'},
        {field: 'amount', label: 'Valor'},
        {field: 'amount_paid', label: 'Valor Pago'},
        {field: 'payment_status', label: 'Status do Pagamento'},
        {field: 'payment_datetime', label: 'Data do Pagamento'},
        {field: 'manual_approval', label: 'Aprovação Manual?'},
        {field: 'approval_datetime', label: 'Data da Aprovação'},
      ],
      exportSortFields: [
        {field: 'id', label: 'ID'},
        {field: 'player_class', label: 'Categoria'},
        {field: 'player', label: 'Atleta'},
        {field: 'created_at', label: 'Data da Inscrição'},
        {field: 'payment_datetime', label: 'Data do Pagamento'},
      ],
      registrationDialog: {
        visible: false,
        id: null
      },
      notifyPlayerModal: {
        visible: false,
        message: '',
        maxCaracters: 1000,
      },
      isSubmitted: false,
    }
  },
  mounted() {
    this.getDependencies()
    this.filters.stage_id = this.stageId
    const searchData = this.prepareSearchData()
    bus.$emit('list-init', {
      domain: 'registration',
      relations: ['stage', 'player_class', 'player.user', 'transaction'],
      data: searchData
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'tenant']),
    isAllChecked() {
      return this.listStore.listData.length > 0 && this.selectedList.length === this.listStore.listData.length
    },
    totalAmount: function () {
      return this.listStore.listData.reduce((total, item) => {
        if (item.stage.settings?.payment_unique && item.data.player_number === 2) {
          return total
        }
        if (item.transaction) {
          return total + item.transaction.amount
        }
        return total + item.amount
      }, 0)
    },
    dependenciesRegistrationStatus() {
      return this.dependencies.registration_status.filter(item => !!item.id)
    },
    dependenciesTransactionStatus() {
      return this.dependencies.transaction_status.filter(item => {
        if (this.selectedStage && !this.selectedStage.settings?.registration_with_payment) {
          return ['created', 'paid'].includes(item.id)
        }
        return true
      })
    },
    selectedStage() {
      return this.dependencies.stages.find(item => item.id === this.filters.stage_id)
    },
    paymentEnabled() {
      return this.selectedStage && (this.selectedStage.settings?.registration_with_payment || false)
    },
    paymentUnique() {
      return this.selectedStage && (this.selectedStage.settings?.payment_unique || false)
    }
  },
  methods: {
    getDependencies() {
      DataService.get([{
        domain: 'stage',
        data: {status: {in: ['active', 'finished']}}
      }, {domain: 'player_class'}, {domain: 'registration_status'}, {domain: 'transaction_status'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    prepareSearchData() {
      let searchData = {}
      if (this.filters.stage_id) {
        searchData.stage_id = this.filters.stage_id
      }
      if (this.filters.name) {
        searchData.player_name = this.filters.name
      }
      if (this.filters.player_class_id) {
        searchData.player_class_id = this.filters.player_class_id
      }
      if (this.filters.status.length) {
        searchData.status = this.filters.status
      }
      if (this.filters.local !== 'all') {
        searchData.local = this.filters.local
      }
      if (this.filters.manual_approval !== 'all') {
        searchData.manual_approval = this.filters.manual_approval === 'yes'
      }
      if (this.filters.transaction_code) {
        searchData.transaction_code = this.filters.transaction_code
      }
      if (this.filters.transaction_status !== '') {
        searchData.transaction_status = this.filters.transaction_status
      }
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      bus.$emit('list-filter', data)
    },
    onFilterClean() {
      const data = {...cloneDeep(defaultFilters), ...cloneDeep(defaultSort)}
      bus.$emit('list-filter-clean', data)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    registrationPlayerData(item) {
      const data = []
      const cpf = item.data?.cpf || item.player?.cpf_cnpj || ''
      const email = item.data?.email || item.player?.email || ''
      const phone = item.data?.phone || item.player?.phone || ''
      if (cpf) {
        data.push(cpf)
      }
      if (email) {
        data.push(email)
      }
      if (phone) {
        data.push(phone)
      }
      return data.join(' - ')
    },
    onExport({format, fields, sort}) {
      const filters = {...this.prepareSearchData()}
      if ('pdf' === format) {
        this.exportPdf(filters, fields, sort)
      } else if ('csv' === format) {
        this.exportCsv(filters, fields, sort)
      }
    },
    exportPdf(filters, fields, sort) {
      bus.$emit('show-loader')
      const searchData = {filters, fields, sort, format: 'pdf'}
      restfulService.post('report/registration', 'export-search', null, searchData)
          .then(response => {
            this.pdfBase64String = response.file
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    exportCsv(filters, fields, sort) {
      bus.$emit('show-loader')
      const data = {filters, fields, sort, format: 'csv'}
      fileService.get('report/registration', 'export-search', null, 'csv', data, false, 'Relatório de Inscrições')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    onUpdateRegistration() {
      this.onFilter()
    },
    resetSelectedList() {
      this.listStore.listData.forEach(entry => {
        entry.checked = false
      })
      this.selectedList.splice(0)
    },
    checkItem(listItem) {
      if (this.selectedList.some(item => item === listItem.id)) {
        this.selectedList = this.selectedList.filter(item => item !== listItem.id)
        listItem.checked = false
      } else {
        this.selectedList.push(listItem.id)
        listItem.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.listStore.listData.map(item => item.id)]
        this.listStore.listData.forEach(entry => {
          entry.checked = true
        })
      }
    },
    async selectedAction(command) {
      if (command.type === 'execute') {
        const {value: confirm} = await confirmDialog({
          title: `Deseja realmente ${command.label}?`,
          icon: 'warning',
        })
        if(confirm) {
          this.executeAction({action: command.action, list: this.selectedList})
        }
      } else if (command.type === 'edit-before') {
        bus.$emit('show-loader')
        if (command.action === 'notify-player') {
          this.notifyPlayerModal.visible = true
        }
        bus.$emit('hide-loader')
      }
    },
    executeAction(data) {
      bus.$emit('show-loader')
      restfulService.post('registration', 'list-selected', null, data)
        .then(response => {
          notifyService.success({title: response.message})
          this.resetSelectedList()
          bus.$emit('hide-loader')
        })
        .catch(e => {
          console.log(e)
          bus.$emit('hide-loader')
        })
    },
    onSendNotifyPlayerModal() {
      if (this.notifyPlayerModal.message === '') {
        return false;
      }
      this.isSubmitted = true
      this.executeAction({
        action: 'notify-player',
        list: this.selectedList,
        notify_player: {
          message: this.notifyPlayerModal.message
        }
      })
      this.notifyPlayerModal = {...{visible: false, message:''}}
      this.isSubmitted = false
    },
    edit(item) {
      bus.$emit('show-loader')
      this.registrationDialog.visible = true
      this.registrationDialog.id = item.id
    },
    closeDialog() {
      this.registrationDialog.id = null
      this.registrationDialog.visible = false
    },
    copyToClipboard(transaction) {
      navigator.clipboard.writeText(transaction.hash)
      this.$notify({
        title: 'Link de pagamento copiado!',
        type: 'success',
        duration: 1000,
        position: 'bottom-right'
      })
    },
    async refund(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente estornar esse pagamento?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('transaction', 'refund', item.id, {})
            .then(async () => {
              notifyService.success({message: 'Pagamento estornado com sucesso'})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
  }
}
</script>

<template>
  <div>
    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
              <list-export @export="onExport"
                           :fields="exportFields"
                           :sort-fields="exportSortFields"
                           :base64-pdf="pdfBase64String"/>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Torneio">
              <el-select v-model="filters.stage_id"
                         filterable
                         clearable
                         placeholder="Selecione um torneio"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option v-for="item in dependencies.stages"
                           :key="item.id"
                           :label="item.name_with_circuit"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Atleta">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por atleta"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Categoria">
              <el-select v-model="filters.player_class_id"
                         filterable
                         clearable
                         placeholder="Selecione uma categoria"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option v-for="item in dependencies.players_class"
                           :key="item.id"
                           :label="item.name_with_sex"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Status da Inscrição" style="width: 250px">
              <el-select v-model="filters.status"
                         filterable
                         clearable
                         multiple
                         placeholder="Selecione um status"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option v-for="item in dependenciesRegistrationStatus"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Status do Pagamento">
              <el-select v-model="filters.transaction_status"
                         filterable
                         clearable
                         placeholder="Selecione um status"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option v-for="item in dependenciesTransactionStatus"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Local">
              <el-select v-model="filters.local"
                         filterable
                         placeholder="Selecione um local"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Online/App" value="app"/>
                <el-option label="Gestão" value="system"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Aprovação Manual">
              <el-select v-model="filters.manual_approval"
                         filterable
                         placeholder="Selecione uma opção"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="yes"/>
                <el-option label="Não" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="selectedStage && (selectedStage.settings?.registration_with_payment || false)"
                          label="ID/Código do Pagamento do Comprovante">
              <el-input v-model="filters.transaction_code"
                        placeholder="ID/Código do Pagamento"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="headerTitle">
        <span>Inscrições</span>
        <div class="entries-selected d-inline-block">
          <el-dropdown :disabled="selectedList.length === 0"
                       @command="selectedAction">
            <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
              <span>Com selecionados:</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{label: 'Confirmar Inscrições', action: 'confirm-registrations', type: 'execute'}">
                <i class="fas fa-check"></i>
                <span class="ml-2">Confirmar Inscrições</span>
              </el-dropdown-item>
              <el-dropdown-item :command="{label: 'Confirmar Pagamentos', action: 'confirm-payments', type: 'execute'}">
                <i class="far fa-money-bill-alt"></i>
                <span class="ml-2">Confirmar Pagamentos</span>
              </el-dropdown-item>
              <el-dropdown-item :command="{label: 'Enviar Lembrete de Pagamento', action: 'payment-reminder', type: 'execute'}">
                <i class="far fa-bell"></i>
                <span class="ml-2">Enviar Lembrete de Pagamento</span>
              </el-dropdown-item>
              <el-dropdown-item :command="{label: 'Gerar Pagamentos', action: 'create-transaction', type: 'execute'}">
                <i class="fas fa-money-bill"></i>
                <span class="ml-2">Gerar Pagamentos</span>
              </el-dropdown-item>
              <el-dropdown-item :command="{label: 'Notificar atleta', action: 'notify-player', type: 'edit-before'}">
                <i class="fab fa-whatsapp"></i>
                <span class="ml-2">Notificar atleta</span>
              </el-dropdown-item>
              <el-dropdown-item :command="{label: 'Reverter para Pendente', action: 'revert-confirmation', type: 'execute'}">
                <i class="fas fa-sync"></i>
                <span class="ml-2">Reverter para Pendente</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-check">
              <el-checkbox @change="checkAll" :value="isAllChecked"/>
            </th>
            <th class="list-table-nowrap">ID</th>
            <th>Torneio</th>
            <th>Categoria</th>
            <th>Atleta</th>
            <th>CPF/E-mail/Telefone</th>
            <th>Camiseta</th>
            <th>Pontos</th>
            <th>Tipo</th>
            <th class="text-right">Valor</th>
            <th class="text-center">Status</th>
            <th class="text-center">Pagamento</th>
            <th>Aprovação manual?</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <th class="list-table-check">
              <el-checkbox @change="checkItem(item)" v-model="item.checked" :disabled="!item.id"/>
            </th>
            <td>{{ item.id }}</td>
            <td width="200">{{ item.stage.name_with_circuit }}</td>
            <td>{{ item.player_class.name_with_sex }}</td>
            <td>{{ item.data?.name || item.player?.name }}</td>
            <td>{{ registrationPlayerData(item) }}</td>
            <td>
              <template v-if="item.data && item.data.shirt_type && item.data.shirt_size">
                {{ item.data.shirt_type | shirtTypeLabel }} {{ item.data.shirt_size }}
              </template>
            </td>
            <td>{{ item.points }}</td>
            <td>{{ item.local === 'app' ? 'Online/App' : 'Gestão' }}</td>
            <td width="200">
              <div class="text-right" style="min-width: 60px;"
                   v-if="!item.stage.settings?.payment_unique || (item.stage.settings?.payment_unique && item.data.player_number === 1)">
                {{ item.amount | currencyFormatter }}
              </div>
            </td>
            <td width="140" class="text-center">
              <registration-status :key="item.id"
                                   :status="item.status"
                                   :registration="item"
                                   @updateRegistration="onUpdateRegistration"/>
            </td>
            <td width="200" class="text-center">
              <registration-payment
                  :key="item.id"
                  :transaction="item.transaction"
                  :registration="item"
                  :payment-enabled="paymentEnabled"
                  :payment-unique="paymentUnique"
                  :player-number="item.data?.player_number"
                  @updateRegistration="onUpdateRegistration"/>
            </td>
            <td>{{ item.manual_approval ? 'Sim' : '-' }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="warning"
                           size="mini"
                           v-if="item.transaction && item.transaction.can_refund"
                           @click="refund(item.transaction)"
                           title="Estornar Pagamento">
                  <i class="fas fa-recycle"></i>
                </el-button>
                <el-button type="success"
                           size="mini"
                           v-if="item.transaction && !item.transaction.is_paid"
                           @click="copyToClipboard(item.transaction)"
                           title="Copiar Link de Pagamento">
                  <i class="fas fa-money-bill"></i>
                </el-button>
                <el-button type="info"
                           size="mini"
                           @click="edit(item)"
                           title="Editar Inscrição">
                  <i class="fas fa-pencil-alt"></i>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="9" align="right">Total:</th>
            <th align="right">{{ totalAmount | currencyFormatter }}</th>
            <th colspan="4"></th>
          </tr>
          </tfoot>
        </table>

      </div>

    </ListMain>

    <gripo-dialog :model.sync="registrationDialog.visible"
                  :actions-close-btn="false"
                  :on-close="closeDialog">
      <template v-slot:header>Dados da Inscrição</template>
      <template v-slot:content>
        <RegistrationForm :registration-id="registrationDialog.id"
                          v-if="registrationDialog.id"
                          @save-registration="onFilter"
                          @on-close="closeDialog"/>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="notifyPlayerModal.visible" width="50vw" height="50vh">
      <template v-slot:header>
        Escreva a notificação para o atleta
      </template>
      <template v-slot:content>
        <el-row>
          <el-form>
            <el-form-item label="Notificação" class="is-required">
              <el-input
                type="textarea"
                v-model="notifyPlayerModal.message"
                ref="notifyPlayerModalMessage"
                :maxlength="notifyPlayerModal.maxCaracters">
              </el-input>
              <div class="el-form-item-info">Máximo {{notifyPlayerModal.maxCaracters}} caracteres</div>
            </el-form-item>
          </el-form>
        </el-row>
      </template>
      <template slot="actions">
          <v-spacer></v-spacer>
          <el-button
              type="primary"
              @click="onSendNotifyPlayerModal"
              :disabled="isSubmitted">Enviar
          </el-button>
        </template>
    </gripo-dialog>

  </div>
</template>
