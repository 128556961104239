<script>
import Vue from 'vue'
import VueBarcodeScanner from 'vue-barcode-scanner'
import ClientComponent from '../../../../components/common/ClientComponent'
import ClientName from '../../../../components/common/ClientName'
import GripoDialog from '../../../../components/common/Dialog'
import EmptyContent from '../../../../components/common/EmptyContent'
import OrderQueueStatus from './labels/QueueStatus'
import FinishOrder from './finish_order/FinishOrder'
import OrderProductsList from './order_products/ProductsList'
import QueuedProducts from './QueuedProducts'

import * as restfulService from '../../../../services/restfulService'
import DataService from '../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'
import {mapGetters} from 'vuex'

import {required} from 'vuelidate/lib/validators'
import {cloneDeep, debounce, orderBy} from 'lodash'
import moment from 'moment'

Vue.use(VueBarcodeScanner, {sensitivity: 200})

export const defaultOrder = {
  id: null,
  created_at: '',
  payment_method_id: '',
  bank_account_id: '',
  amount: 0,
  discount: 0,
  label: '',
  client: {
    id: '',
    name: '',
    balance: 0,
    debit: 0,
    has_user: false,
    address_available: false,
    coupons: [],
    address: {},
  },
  products: [],
  show_all_products: false,
  created_by_user: {
    id: '',
    name: ''
  },
  notes: '',
  clientPlans: []
}

const productsDependencyObject = {
  domain: 'product',
  data: {active: true, category_id: {isNotNull: true}}
}

export default {
  components: {
    ClientComponent,
    ClientName,
    GripoDialog,
    EmptyContent,
    OrderQueueStatus,
    FinishOrder,
    OrderProductsList,
    QueuedProducts
  },
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isLoadingOrders: false,
      isSubmitted: false,
      isSelectProductsModalVisible: false,
      isChangeQuantityModalVisible: false,
      isFinishOrderModalVisible: false,
      isSelectSingleProductModalVisible: false,
      isKitchenOrderModalVisible: false,
      isKitchenOrderNotesModalVisible: false,
      useBalance: false,
      handleAmountMoney: true,
      showOverdueOrders: true,
      isChangingQuantity: false,
      searchProducts: '',
      showFilters: false,
      filters: {
        sort_by: 'client_asc',
      },
      showKitchenOption: false,
      showOnlyUserOrders: false,
      list: [],
      dueDateOrders: [],
      dependencies: {
        products: [],
        clients: []
      },
      showCurrentDateModalDatePicker: false,
      currentDate: '',
      selectedProducts: [],
      selectedOrder: null,
      searchClientFocus: false,
      createOrderOnFirstClient: false,
      client: {...defaultOrder.client},
      product: {
        id: '',
        description: '',
        quantity: 1,
        product: {
          id: '',
          name: ''
        }
      },
      singleProduct: {
        product_id: '',
        clients: []
      },
      currentOrderId: '',
      order: {...cloneDeep(defaultOrder)},
      discountAmount: 0,
      queueProduct: {
        order_product_id: null,
        notes: null
      },
      showCountProducts: 6,
      showProductsList: [],
      isCreateEmptyOrderModalVisible: false,
      isAttachScheduleModalVisible: false,
      emptyOrderLabel: '',
      attachSchedule: {
        courts: [],
        schedules: [],
        data: {
          order_id: '',
          order_product_id: '',
          court_id: '',
          schedule_id: '',
        },
      }
    }
  },
  validations: {
    order: {
      id: {required}
    },
    attachSchedule: {
      data: {
        court_id: {required},
        schedule_id: {required},
        order_product_id: {required},
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'currentUser', 'idle']),
    moneyMask() {
      return currencyConfig()
    },
    websocketKeyOrdersUpdate() {
      return 'ordersUpdate'
    },
    websocketKeyOrdersUpdateName() {
      return `${this.websocketKeyOrdersUpdate}.${this.tenant.id}`
    },
    websocketKeyOrdersUpdateListen() {
      return `.${this.websocketKeyOrdersUpdate}`
    },
    websocketKeyProductsUpdate() {
      return 'productsUpdate'
    },
    websocketKeyProductsUpdateName() {
      return `${this.websocketKeyProductsUpdate}.${this.tenant.id}`
    },
    websocketKeyProductsUpdateListen() {
      return `.${this.websocketKeyProductsUpdate}`
    },
    websocketKeyStockUpdate() {
      return 'stockUpdate'
    },
    websocketKeyStockUpdateName() {
      return `${this.websocketKeyStockUpdate}.${this.tenant.id}`
    },
    websocketKeyStockUpdateListen() {
      return `.${this.websocketKeyStockUpdate}`
    },
    currentDateFormatted() {
      return this.currentDate ? this.dateFormat(this.currentDate, 'br') : ''
    },
    currentDateWeekday() {
      return this.weekdayString(moment(this.currentDate, 'YYYY-MM-DD').weekday())
    },
    updatingOrders() {
      return this.list.some(item => item.loading)
    },
    getFilter() {
      let filter = 'label'
      let direction = this.filters.sort_by.includes('asc') ? 'asc' : 'desc'
      if (this.filters.sort_by.includes('number')) {
        filter = 'number'
      }
      return [filter, direction]
    },
    listFiltered() {
      return orderBy(this.list.filter(item => {
        if (!this.showOverdueOrders && item.overdue) {
          return false
        }
        if (this.showOnlyUserOrders && item.created_by_user.id !== this.currentUser.id) {
          return false
        }
        return true
      }), [this.getFilter[0]], [this.getFilter[1]])
    },
    productsByCategory() {
      let categories = []
      this.dependencies.products.forEach(product => {
        let category = categories.find(item => item.id === product.category_id)
        if (!category) {
          categories.push({
            id: product.category_id,
            name: product.category.name,
            order: product.category.order || 0,
            products: []
          })
          category = categories.find(item => item.id === product.category_id)
        }
        if (!this.searchProducts ||
            this.selectedProducts.some(item => item.product_id === product.id) ||
            (this.searchProducts && product.description.toLowerCase().includes(this.searchProducts.toLowerCase()))) {
          category.products.push(product)
        }
      })
      categories.map(item => {
        item.products = [...orderBy(item.products, ['order', 'description'], ['asc', 'asc'])]
      })
      return [...orderBy(categories, ['order', 'name'], ['asc', 'asc'])]
    },
    attachScheduleCourts() {
      return this.attachSchedule.courts.filter(item => item.hours.length).map(item => {
        return {
          id: item.id,
          name: item.name,
          hours: item.hours,
        }
      })
    },
    attachScheduleSchedules() {
      const court = this.attachScheduleCourts.find(item => item.id === this.attachSchedule.data.court_id)
      if (court) {
        return court.hours.map(item => item.schedule)
      }
      return []
    }
  },
  watch: {
    currentDate: function (value) {
      if (value) {
        this.getOrders()
        this.getDueDateOrders()
      }
    },
    idle: {
      deep: true,
      handler: function (value, oldValue) {
        if (!value.current && oldValue.current && value.seconds > 120) {
          const currentDate = this.currentDate
          this.currentDate = ''
          this.currentDate = currentDate
        }
      }
    }
  },
  async mounted() {
    await this.getDependencies()
    this.getCurrentDate()
    this.startWebsocket()
    this.isLoadingData = false
    this.$barcodeScanner.init(this.onBarcodeScanned)
  },
  destroyed() {
    this.$barcodeScanner.destroy()
    window.Echo.channel(this.websocketKeyOrdersUpdateName)
        .stopListening(this.websocketKeyOrdersUpdateListen)
    window.Echo.channel(this.websocketKeyProductsUpdateName)
        .stopListening(this.websocketKeyProductsUpdateListen)
    window.Echo.channel(this.websocketKeyStockUpdateName)
        .stopListening(this.websocketKeyStockUpdateListen)
  },
  methods: {
    async getDependencies() {
      return new Promise(resolve => {
        DataService.get([productsDependencyObject, {domain: 'payment_method'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    async getCurrentDate() {
      this.currentDate = await restfulService.get('current-date')
    },
    startWebsocket() {
      window.Echo.channel(this.websocketKeyOrdersUpdateName)
          .listen(this.websocketKeyOrdersUpdateListen, ({order, action}) => {
            const skitGetOrders = this.currentOrderId && order?.id === this.currentOrderId && ['delete', 'update'].includes(action)
            if (!this.isLoadingOrders && !skitGetOrders) {
              this.getOrders()
            }
            this.currentOrderId = ''
          })
      window.Echo.channel(this.websocketKeyProductsUpdateName)
          .listen(this.websocketKeyProductsUpdateListen, () => {
            this.updateProductsList()
          })
      window.Echo.channel(this.websocketKeyStockUpdateName)
          .listen(this.websocketKeyStockUpdateListen, () => {
            this.updateProductsList()
          })
    },
    updateOrderData(order, action) {
      if (!order) {
        return
      }
      if (this.updatingOrders) {
        setTimeout(() => {
          this.updateOrderData(order, action)
        }, 1200)
        return
      }
      order.loading = true
      action = order?.action || action || 'update'
      let orderIndex = this.list.findIndex(item => item.id === order.id)
      if (orderIndex === -1 && action === 'update') {
        this.list.push(order)
        orderIndex = this.list.findIndex(item => item.id === order.id)
      }
      if (orderIndex > -1) {
        if (action !== 'delete') {
          this.list.splice(orderIndex, 1, order)
        }
        setTimeout(() => {
          if (action === 'delete') {
            this.list.splice(orderIndex, 1)
          } else {
            order.loading = false
            this.list.splice(orderIndex, 1, order)
          }
        }, 1000)
      } else {
        order.loading = false
      }
    },
    updateProductsList() {
      DataService.get([productsDependencyObject]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    toggleFilters() {
      this.showFilters = !this.showFilters
    },
    getOrders(forced) {
      forced = forced || false
      this.isLoadingOrders = true
      bus.$emit('show-loader')
      const data = {date: this.currentDate, forced}
      restfulService.post('order', 'daily', null, data)
          .then(response => {
            this.list = [...response]
            bus.$emit('hide-loader')
            this.isLoadingOrders = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isLoadingOrders = false
            console.log(e)
          })
    },
    removeOrder(orderId) {
      this.currentOrderId = orderId
      this.list = [...this.list.filter(item => item.id !== orderId)]
    },
    confirmOrder(orderId) {
      this.currentOrderId = orderId
      this.list = [...this.list.filter(item => item.id !== orderId)]
      this.updateProductsList()
    },
    getDueDateOrders() {
      bus.$emit('show-loader')
      const data = {date: this.currentDate}
      restfulService.post('order', 'due-date', null, data)
          .then(response => {
            if (response && Array.isArray(response)) {
              this.dueDateOrders = [...response.map(item => item.date)]
            }
            bus.$emit('hide-loader')
          })
    },
    onSelectClient(client) {
      bus.$emit('show-loader')
      this.onCreateOrder(client.id)
    },
    onSelectOrder(orderId) {
      if (this.selectedOrder !== orderId) {
        this.selectedOrder = orderId
      } else {
        this.selectedOrder = null
      }
    },
    barcodeScanSelectProduct: debounce(function (barcode) {
      const product = this.dependencies.products.find(item => item.barcode === barcode + '')
      if (product) {
        const selectedProductIndex = this.selectedProducts.findIndex(item => item.product_id === product.id)
        if (selectedProductIndex > -1) {
          this.selectedProducts[selectedProductIndex].quantity += 1
        } else {
          this.selectedProducts.push({
            product_id: product.id,
            quantity: 1
          })
        }
        if (!this.selectedOrder) {
          return
        } else {
          this.order = {...cloneDeep(this.list.find(item => item.id === this.selectedOrder))}
        }
        this.saveProducts()
      }
    }, 300),
    onBarcodeScanned(barcode, input) {
      input = input || true
      this.createOrderOnFirstClient = false
      if (input || (!input && this.selectedOrder)) {
        this.barcodeScanSelectProduct(barcode)
      }
      if (input && this.searchClientFocus) {
        this.createOrderOnFirstClient = true
      }
    },
    async onCreateOrder(clientId, label) {
      const orderData = {client_id: clientId, date: this.currentDate, label: label ?? null}
      try {
        const order = await restfulService.post('order', 'products', null, orderData)
        this.currentOrderId = order.id
        this.updateOrderData(order)
        this.isSubmitted = false
        this.$refs.clientComponentPlayerDaily.reset()
        this.addProducts(order)
      } catch (e) {
        bus.$emit('hide-loader')
        this.isSubmitted = false
        console.log(e)
      }
    },
    handleSelectProductsVisible() {
      this.searchProducts = null
      this.selectedProducts = [...this.order.products.map(item => {
        return {
          product_id: item.product_id,
          quantity: item.quantity
        }
      })]
      this.isSelectProductsModalVisible = !this.isSelectProductsModalVisible
      if (this.isSelectProductsModalVisible) {
        setTimeout(() => {
          this.$refs.searchProducts.focus()
          bus.$emit('hide-loader')
        }, 200)
      } else {
        this.searchProducts = null
        bus.$emit('hide-loader')
      }
    },
    handleChangeQuantityVisible() {
      this.isChangeQuantityModalVisible = !this.isChangeQuantityModalVisible
      if (!this.isChangeQuantityModalVisible) {
        this.order.id = null
      }
    },
    handleKitchenOrderVisible() {
      this.isKitchenOrderModalVisible = !this.isKitchenOrderModalVisible
    },
    handleKitchenOrderNotesVisible(product) {
      this.queueProduct = product ? {...product} : {}
      this.isKitchenOrderNotesModalVisible = !this.isKitchenOrderNotesModalVisible
    },
    addProducts(order) {
      this.order = {...cloneDeep(order)}
      this.handleSelectProductsVisible()
      bus.$emit('hide-loader')
    },
    checkSelectedProduct(product) {
      return this.selectedProducts.some(item => item.product_id === product.id)
    },
    selectProduct(product) {
      if (!this.selectedProducts.some(item => item.product_id === product.id)) {
        this.selectedProducts.push({
          product_id: product.id,
          quantity: 1
        })
      } else {
        this.selectedProducts = this.selectedProducts.filter(item => item.product_id !== product.id)
      }
    },
    getProductQuantity(product) {
      return this.selectedProducts.find(item => item.product_id === product.id).quantity
    },
    selectProductQuantity(quantity, product) {
      const productFound = this.selectedProducts.find(item => item.product_id === product.id)
      if (productFound) {
        productFound.quantity = quantity
      }
    },
    totalAmountByClient(client) {
      if (client.products && client.products.length) {
        return client.products
            .filter((product) => !product.payment)
            .reduce((total, product) => total + (!product.split ? ((product.quantity * product.price) - product.discount) : product.split_price), 0)
      }
      return 0
    },
    async saveProducts() {
      bus.$emit('show-loader')
      const data = {id: this.order.id, products: this.selectedProducts, date: this.currentDate}
      try {
        const response = await restfulService.post('order', 'products', null, data)
        this.currentOrderId = data.id
        this.updateOrderData(response)
        if (this.isSelectProductsModalVisible) {
          this.isSelectProductsModalVisible = false
        }
        bus.$emit('hide-loader')
      } catch (e) {
        bus.$emit('hide-loader')
        console.log(e)
      }
    },
    async removeProduct(order, product) {
      let orderIndex = this.list.findIndex(item => item.id === order.id)
      restfulService.put('order', 'product/' + product.id + '/delete', order.id)
          .then(() => {
            this.list[orderIndex].products = this.list[orderIndex].products.filter(item => item.id !== product.id)
          })
          .catch(e => {
            console.log(e)
          })
    },
    changeProductQuantity(order) {
      this.handleChangeQuantityVisible()
      setTimeout(() => {
        this.order = {...cloneDeep(order)}
      }, 50)
    },
    async attachScheduleToProduct(productOrder, order) {
      bus.$emit('show-loader')
      bus.$emit('show-loader')
      this.attachSchedule.data.court_id = ''
      this.attachSchedule.data.schedule_id = ''
      this.attachSchedule.courts = (await restfulService.post('schedules', null, null, {
        date: this.currentDate,
        remove_available: true
      })).courts
      this.attachSchedule.data.order_id = order.id
      this.attachSchedule.data.order_product_id = productOrder.id
      this.isAttachScheduleModalVisible = true
      bus.$emit('hide-loader')
    },
    async onAttachScheduleToProduct() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      restfulService.post('order', 'attach-schedule', this.attachSchedule.data.order_id, {
        ...this.attachSchedule.data
      })
          .then(response => {
            const orderIndex = this.list.findIndex(item => item.id === response.id)
            this.list.splice(orderIndex, 1, response)
            this.isAttachScheduleModalVisible = false
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    openKitchen(order) {
      bus.$emit('show-loader')
      restfulService.get('order', null, order.id, null, ['products.product', 'products.kitchen_queue'])
          .then(response => {
            this.order = {...cloneDeep(order)}
            this.order.products = [...response.products.filter(item => item.product.kitchen_enabled)]
            this.handleKitchenOrderVisible()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    addProductQueue(product) {
      bus.$emit('show-loader')
      const data = {order_product_id: product.id, notes: product.notes}
      restfulService.post('order', 'add-queue', product.order_id, data)
          .then(response => {
            product.kitchen_queue.push(response)
            this.handleKitchenOrderNotesVisible()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    removeProductQueue(queue, product, order) {
      bus.$emit('show-loader')
      const data = {order_product_queue_id: queue.id}
      restfulService.post('order', 'remove-queue', order.id, data)
          .then(() => {
            product.kitchen_queue = product.kitchen_queue.filter(item => item.id !== queue.id)
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async finishOrder(order) {
      bus.$emit('show-loader')
      this.useBalance = false
      this.order = {...cloneDeep(order)}
      this.order.amount = 0
      this.order.discount = 0
      this.order.payment_method_id = ''
      this.isFinishOrderModalVisible = false
      setTimeout(() => {
        this.isFinishOrderModalVisible = true
      })
    },
    showAll(order) {
      order.show_all_products = !order.show_all_products
    },
    openCreateEmptyOrder() {
      this.isCreateEmptyOrderModalVisible = true
      this.emptyOrderLabel = ''
      setTimeout(() => {
        this.$refs.emptyOrderLabelInput.focus()
      }, 300)
    },
    async createEmptyOrder() {
      bus.$emit('show-loader')
      this.isCreateEmptyOrderModalVisible = false
      await this.onCreateOrder(null, this.emptyOrderLabel)
    },
    updateOrders(orders) {
      if (Array.isArray(orders)) {
        orders.forEach(order => this.updateOrderData(order))
      } else {
        this.updateOrderData(orders)
      }
    },
    saveProductChangeQuantity(value, product) {
      bus.$emit('show-loader')
      let data = {
        id: product.order_id,
        products: [{
          product_id: product.product_id,
          quantity: value,
        }]
      }
      restfulService.post('order', 'products', null, data)
          .then(() => {
            this.currentOrderId = data.id
            product.quantity = value
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <template v-if="this.dependencies.products.length > 0">
      <div class="entry-daily">
        <div class="search-client">
          <div class="header-bar-search">
            <div class="search-box">
              <client-component :client-id.sync="client.id"
                                ref="clientComponentPlayerDaily"
                                :outline="false"
                                @selectClient="onSelectClient"
                                :hide-loader-after-select="false"/>
            </div>
            <div class="dates-box menu-datepicker -orders"
                 v-show="!$vuetify.breakpoint.smAndDown">
              <v-menu ref="modalDatePicker"
                      :close-on-content-click="false"
                      v-model="showCurrentDateModalDatePicker"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px">
                <v-text-field slot="activator"
                              v-model="currentDateFormatted"
                              hide-details
                              :label="currentDateWeekday"
                              outline>
                  <div slot="prepend-inner">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                </v-text-field>
                <v-date-picker v-model="currentDate"
                               :events="dueDateOrders"
                               event-color="red lighten-1"
                               @input="showCurrentDateModalDatePicker = false"
                               locale="pt-br"/>
              </v-menu>
            </div>
            <el-button @click="showCurrentDateModalDatePicker = true"
                       :size="$vuetify.breakpoint.xs ? 'mini' : 'small'"
                       class="ml-1"
                       v-if="$vuetify.breakpoint.smAndDown">
              <i class="fas fa-calendar-alt"></i>
            </el-button>
            <el-button type="success"
                       @click="openCreateEmptyOrder"
                       :size="$vuetify.breakpoint.xs ? 'mini' : 'small'"
                       class="ml-1"
                       title="Criar comanda avulsa">
              <i class="fas fa-plus"></i>
            </el-button>
            <el-button type="warning"
                       @click="$router.push({name: 'order.kitchen.index'})"
                       class="ml-1"
                       title="Cozinha"
                       :size="$vuetify.breakpoint.xs ? 'mini' : 'small'">
              <i class="fas fa-utensils"></i>
            </el-button>
            <queued-products v-if="currentDate"
                             :date="currentDate"
                             :has-modal-opened="isFinishOrderModalVisible || isChangeQuantityModalVisible || isSelectProductsModalVisible"/>
            <el-button @click="getOrders(true)"
                       class="ml-1"
                       title="Recarregar Comandas"
                       :size="$vuetify.breakpoint.xs ? 'mini' : 'small'">
              <i class="fas fa-sync"></i>
            </el-button>
            <v-btn class="filters" icon
                   @click="toggleFilters"
                   v-if="$vuetify.breakpoint.mdAndUp"
                   title="Exibir Filtros">
              <v-icon color="grey">fa-filter</v-icon>
            </v-btn>
          </div>
        </div>

        <v-alert v-if="dueDateOrders.length > 0" class="mb-3"
                 :value="true"
                 dismissible
                 type="warning"
                 outline>
          <slot name="content">
            Existem comandas abertas em dias anteriores, abra o calendário para verificar os dias com comandas em
            aberto!
          </slot>
        </v-alert>

        <div class="daily-sort flex-c" v-if="showFilters || $vuetify.breakpoint.smAndDown">
          <div class="mr-2">
            <div class="text">
              Exibir {{ showCountProducts !== 0 ? showCountProducts : 'todos' }} Produtos:
            </div>
            <el-select v-model="showCountProducts"
                       size="mini"
                       style="width: 80px">
              <el-option label="4" :value="4"/>
              <el-option label="6" :value="6"/>
              <el-option label="8" :value="8"/>
              <el-option label="10" :value="10"/>
              <el-option label="Todos" :value="0"/>
            </el-select>
          </div>
          <div class="mr-2">
            <div class="text">
              Ordernar por:
            </div>
            <el-select v-model="filters.sort_by"
                       size="mini">
              <el-option label="Cliente ASC" value="client_asc"/>
              <el-option label="Cliente DESC" value="client_desc"/>
              <el-option label="Nº Comanda ASC" value="number_asc"/>
              <el-option label="Nº Comanda DESC" value="number_desc"/>
            </el-select>
          </div>
          <div class="mr-2">
            <div class="text">
              Filtrar Minhas Comandas:
            </div>
            <el-switch v-model="showOnlyUserOrders"
                       active-color="#13ce66"
                       :active-value="true"
                       active-text="SIM"
                       inactive-color="#ff4949"
                       :inactive-value="false">
            </el-switch>
          </div>
          <div>
            <div class="text">
              Habilitar Cozinha:
            </div>
            <el-switch v-model="showKitchenOption"
                       active-color="#13ce66"
                       :active-value="true"
                       active-text="SIM"
                       inactive-color="#ff4949"
                       :inactive-value="false">
            </el-switch>
          </div>
        </div>

        <div class="entry-daily-content">
          <template v-for="order in listFiltered">
            <div
                :class="['el-card el-card-daily order-loading el-loading-mask-light', {'order-selected': selectedOrder === order.id}]"
                :title="`Comanda ${order.number || ''}`"
                :key="order.id"
                v-loading="order.loading">
              <div class="order-number"
                   v-if="!order.loading"
                   @click="onSelectOrder(order.id)">{{ order.number || '' }}
              </div>
              <div class="el-card-daily-container">
                <div class="container-body">
                  <h3>
                    <client-name :client="order.client" :custom-name="order.label"/>
                  </h3>
                  <div class="products">
                    <template v-if="order.products && order.products.length > 0">
                      <div class="product-item"
                           v-for="(product, index) in order.products"
                           v-show="showCountProducts === 0 || (showCountProducts > 0 && (index < showCountProducts || index >= showCountProducts && order.show_all_products))"
                           :key="index">
                        <div class="quantity-description"
                             @click="!product.payment && changeProductQuantity(order)">
                          <span v-if="product.split"
                                class="quantity quantity-icon"
                                title="Produto divido">
                            <i class="fas fa-users"></i>
                          </span>
                          <template v-else>
                            <el-popover
                                placement="right"
                                width="150"
                                trigger="hover">
                              <div slot="reference"
                                   :class="['font-weight-bold quantity', {'-decimals': product.product.unit !== 'UN'}]">
                                {{ product.quantity }}
                              </div>
                              <div>
                                <h4 class="text-center mt-0 mb-1">
                                  {{ product.product.description }}
                                </h4>
                                <el-input-number :value="product.quantity"
                                                 size="small"
                                                 :precision="product.product.unit === 'UN' ? 0 : 3"
                                                 :step="product.product.unit === 'UN' ? 1 : 0.001"
                                                 :min="0.001"
                                                 @change="saveProductChangeQuantity($event, product)"/>
                              </div>
                            </el-popover>
                          </template>
                          <span class="description">{{ product.product.description }}</span>
                        </div>
                        <div class="price-remove">
                          <div class="price"
                               v-if="product.payment && product.payment.paid">
                            Pago
                          </div>
                          <div class="price"
                               v-else
                               @click="changeProductQuantity(order)">
                            {{
                              (!product.split
                                  ? ((product.price * product.quantity) - product.discount)
                                  : product.split_price
                              ) | currencyFormatter
                            }}
                          </div>
                          <div :title="product.schedule.description"
                               v-if="!!product.schedule"
                               :class="['schedule text-ellipsis', `-${product.schedule.type}`]">
                            {{ product.schedule.description }}
                          </div>
                          <el-button type="default"
                                     v-if="product.product.type === 'service' && !product.schedule"
                                     @click="attachScheduleToProduct(product, order)"
                                     size="mini">
                            Vincular Horário
                          </el-button>
                          <div class="remove">
                            <el-popconfirm
                                :title="`O produto será removido da comanda`"
                                confirm-button-text="Sim, confirmar!"
                                cancel-button-text="Não"
                                icon=""
                                v-if="!product.split"
                                @confirm="removeProduct(order, product)">
                              <el-button type="danger"
                                         slot="reference"
                                         size="mini">
                                <i class="fas fa-times"></i>
                              </el-button>
                            </el-popconfirm>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-else class="text-center pt-1 pb-1 font-weight-bold text-danger">
                      Nenhum produto selecionado
                    </div>
                  </div>
                  <div class="text-center pt-1 pb-1"
                       v-if="showCountProducts > 0 && order.products && order.products.length > showCountProducts">
                    <el-button type="default"
                               size="mini"
                               @click="showAll(order)">
                      <span key="showMoreProducts" v-if="!order.show_all_products"><i class="fas fa-plus"></i></span>
                      <span key="showLessProducts" v-else><i class="fas fa-minus"></i></span>
                      <span class="ml-2">
                        Mostrar {{ order.show_all_products ? 'menos' : 'mais' }} produtos
                      <span v-if="!order.show_all_products">
                        ({{ order.products.length - showCountProducts }})
                      </span>
                    </span>
                    </el-button>
                  </div>
                </div>
                <div class="container-footer">
                  <h4 class="titles products-total">
                    Total: {{ totalAmountByClient(order) | currencyFormatter }}
                  </h4>
                  <div class="order-actions">
                    <el-button type="info"
                               size="mini"
                               @click="addProducts(order)"
                               class="add-product">
                      <i class="fas fa-plus-circle"></i>
                      <span>Produtos</span>
                    </el-button>
                    <el-button type="success"
                               class="finish-order"
                               size="mini"
                               @click="finishOrder(order)">
                      <i class="fas fa-check"></i>
                      <span>Finalizar</span>
                    </el-button>
                    <el-button type="warning"
                               v-if="showKitchenOption"
                               class="finish-order"
                               size="mini"
                               @click="openKitchen(order)">
                      <i class="fas fa-utensils"></i>
                      <span>Cozinha</span>
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <gripo-dialog :model.sync="isSelectProductsModalVisible"
                      width="90vw"
                      top="1vh">
          <template slot="header">
            Comanda: #{{ order.number }} | {{ order.client.name }} / Selecione os produtos
          </template>
          <template slot="content">
            <div class="flex-c">
              <div class="select-product-search">
                <el-input v-model="searchProducts"
                          ref="searchProducts"
                          @input="onBarcodeScanned($event, true)"
                          placeholder="Pesquise pelo nome do produto"></el-input>
              </div>
            </div>
            <div class="select-products select-products-list">
              <div class="list-item" v-for="(category, index) in productsByCategory" :key="index">
                <strong class="product-category-name -bg">{{ category.name }}</strong>
                <div class="product-item"
                     v-for="item in category.products"
                     :class="{'is-selected': checkSelectedProduct(item)}">
                  <div :class="['product-content']"
                       @click="selectProduct(item)">
                    <h3 :class="{'-big': item.description.length < 15}">{{ item.description }}</h3>
                    <p>{{ item.price | currencyFormatter }} - Estoque: {{ item.stock }}</p>
                  </div>
                  <div class="product-quantity" v-if="checkSelectedProduct(item)">
                    <el-input-number :value="getProductQuantity(item)"
                                     size="small"
                                     :precision="item.unit === 'UN' ? 0 : 3"
                                     :step="item.unit === 'UN' ? 1 : 0.001"
                                     :min="0.001"
                                     controls-position="right"
                                     @change="selectProductQuantity($event, item)"/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="actions">
            <v-spacer></v-spacer>
            <el-button type="primary"
                       @click="saveProducts"
                       :disabled="isSubmitted"
                       :loading="isSubmitted">
              <i class="fas fa-check"></i>
              <span>Confirmar Seleção</span>
            </el-button>
          </template>
        </gripo-dialog>

        <gripo-dialog :model.sync="isChangeQuantityModalVisible"
                      width="80vw"
                      top="1vh"
                      :persistent="false"
                      :actions-close-btn="false"
                      :content-class="['quantity-dialog']">
          <template slot="header">Comanda de: {{ order.client.name }}</template>
          <template slot="content">
            <el-form ref="order"
                     :model="order"
                     label-position="top">
              <div class="select-products">
                <order-products-list v-if="order.id"
                                     :orders="[...list]"
                                     :order="{...order}"
                                     @update-orders="updateOrders"/>
              </div>
            </el-form>
          </template>
        </gripo-dialog>

        <finish-order :show-dialog.sync="isFinishOrderModalVisible"
                      :list.sync="list"
                      :order="{...order}"
                      @updateList="getOrders"
                      @removeOrder="removeOrder"
                      @confirmOrder="confirmOrder"/>

        <gripo-dialog :model.sync="isKitchenOrderModalVisible"
                      width="80vw"
                      top="1vh"
                      :content-class="['finish-order-dialog']">
          <template slot="header">Cozinha: {{ order.client.name }}</template>
          <template slot="content">
            <div class="select-products kitchen-products" v-if="order.products.length > 0">
              <div class="product-item"
                   v-for="product in order.products">
                <h3>{{ product.product.description }}</h3>
                <p v-for="queue in product.kitchen_queue">
                  <span class="pr-2">{{ dateTimeFormatTime(queue.updated_at) }}</span>
                  <order-queue-status :status="queue.status"/>
                  <el-button type="danger"
                             v-if="queue.status === 'created'"
                             circle
                             size="mini"
                             @click="removeProductQueue(queue, product, order)">
                    <i class="fas fa-times"></i>
                  </el-button>
                </p>
                <el-button type="success"
                           size="small"
                           @click="handleKitchenOrderNotesVisible(product)">
                  <i class="fas fa-plus"></i>
                  <span>1 - Enviar para Fila</span>
                </el-button>
              </div>
            </div>
            <div v-else class="text-center pt-2 fs-18">
              Nenhum produto habilitado para ir para a cozinha
            </div>
          </template>
        </gripo-dialog>

        <gripo-dialog :model.sync="isKitchenOrderNotesModalVisible"
                      width="60vw"
                      top="2vh"
                      :content-class="['finish-order-dialog']">
          <template slot="header">Observações do Pedido da Cozinha</template>
          <template slot="content">
            <el-input type="textarea" v-model="queueProduct.notes"
                      placeholder="Digite aqui as observações do pedido"
                      :rows="3"></el-input>
          </template>
          <template slot="actions">
            <v-spacer></v-spacer>
            <el-button @click="addProductQueue(queueProduct)" type="success">
              <span>Confirmar</span>
            </el-button>
          </template>
        </gripo-dialog>

        <gripo-dialog :model.sync="isCreateEmptyOrderModalVisible"
                      width="60vw"
                      top="2vh">
          <template slot="header">Abrir comanda avulsa</template>
          <template slot="content">
            <el-input type="text" v-model="emptyOrderLabel"
                      ref="emptyOrderLabelInput"
                      placeholder="Digite um nome para a comanda"
                      @keyup.enter.native="createEmptyOrder"></el-input>
          </template>
          <template slot="actions">
            <v-spacer></v-spacer>
            <el-button @click="createEmptyOrder" type="success">
              <span>Confirmar</span>
            </el-button>
          </template>
        </gripo-dialog>

        <gripo-dialog :model.sync="isAttachScheduleModalVisible"
                      width="60vw"
                      top="2vh">
          <template slot="header">Vincular Horário</template>
          <template slot="content">
            <el-form label-position="top">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Selecione uma Quadra">
                    <v-select :items="attachScheduleCourts"
                              v-model="attachSchedule.data.court_id"
                              single-line
                              outline
                              placeholder="Selecione uma Quadra"
                              item-text="name"
                              item-value="id"
                              hide-details
                              no-data-text="Quadras não encontradas"
                              clearable/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Selecione o Horário">
                    <v-select :items="attachScheduleSchedules"
                              v-model="attachSchedule.data.schedule_id"
                              :disabled="!attachSchedule.data.court_id"
                              single-line
                              outline
                              placeholder="Selecione o Horário"
                              item-text="start_hour"
                              item-value="id"
                              hide-details
                              no-data-text="Horários não encontrados"
                              clearable/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
          <template slot="actions">
            <v-spacer></v-spacer>
            <el-button @click="onAttachScheduleToProduct"
                       type="success"
                       :disabled="isSubmitted || $v.attachSchedule.$invalid">
              <span>Confirmar</span>
            </el-button>
          </template>
        </gripo-dialog>

      </div>
    </template>
    <empty-content v-else-if="!isLoadingData">
      <template slot="content">
        <div class="text-center">
          <h3>Para gerenciar as comandas você precisa ter produtos cadastrados</h3>
          <router-link :to="{ name: 'product.index' }"
                       class="el-button el-button--info el-button--text"
                       title="Gerenciar Produtos">
            Gerenciar Produtos
          </router-link>
        </div>
      </template>
    </empty-content>
  </div>
</template>

<style lang="scss">

.search-client {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #FBFBFD;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  padding: 5px 0;

  .header-bar-search {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .filters {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }

  .search-box {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 35%;
    position: relative;
    min-height: 48px;
  }

  .dates-box {
    padding: 0 10px;
  }

  .order-overdue {
    div:first-child {
      line-height: 16px;

      strong {
        font-size: 13px;
      }
    }
  }
}

.daily-sort {
  margin-top: 0;
  justify-content: flex-end !important;

  .text {
    font-size: 12px;
    color: #494949;
  }
}


.entry-daily {
  padding-top: 65px;

  .entry-daily-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 10px;

    .el-card-daily {
      margin: 0 5px 15px;
      padding: 0;
      width: 19%;
      position: relative;
      overflow: visible;
      box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.2);

      &.order-loading {
        z-index: 2;
      }

      &.order-selected {
        border-color: green;
        border-radius: 3px;
        box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.3);

        .order-number {
          background-color: green;
        }
      }

      .order-number {
        cursor: pointer;
        position: absolute;
        left: -5px;
        top: -6px;
        width: 20px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        background-color: #333;
        font-weight: bold;
        font-size: 12px;
        color: #FFF;
        border-radius: 5px;
        transition: background-color ease-in-out .2s;
      }

      .el-card-daily-container {
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        .container-body,
        .container-footer {
          width: 100%;
        }

        .container-footer {
          align-self: flex-end;
        }
      }

      h3 {
        font-weight: 500;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 17px;
        letter-spacing: -.02em;
        padding: 4px 12px 2px;
        line-height: 21px;
        background-color: #fcfcfc;

        .order-overdue {
          font-size: 12px;
          font-weight: normal;
          padding-left: 6px;
        }
      }

      .titles {
        text-align: center;
        padding: 4px 0;
        color: #8c8c8c;
        margin: 0;
      }

      .products {
        border-top: 1px solid #f1f1f1;

        .product-item {
          padding: 3px 2px;
          border-bottom: 1px solid #e7e7e7;

          &:hover {
            background-color: #f1f1f1;
          }

          .quantity-description {
            display: flex;
            align-items: center;

            .quantity {
              width: 30px;
              color: #6b67a2;
              margin-right: 2px;
              font-size: 15px;
              line-height: 14px;
              letter-spacing: -0.05em;
              text-align: center;

              &.-decimals {
                width: 45px;
              }

              &.quantity-icon {
                text-align: center;
                font-size: 12px;
              }
            }

            .description {
              line-height: 16px;
              font-size: 13px;

              strong {
                padding: 0 2px 0;
                font-size: 18px;
              }
            }
          }

          .price-remove {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 1px;

            .price {
              font-size: 13px;
              line-height: 14px;
              padding: 1px 6px;
              border: 1px solid #d9d9d9;
              background-color: #f1f1f1;
              border-radius: 6px;
              font-weight: bold;
              color: #656565;
            }

            .schedule {
              font-size: 12px;
              line-height: 14px;
              padding: 1px 6px;
              border-radius: 6px;
              font-weight: bold;
              max-width: 100px;

              &.-system {
                border: 1px solid #ececec;
                background-color: #fafafa;
                color: #5f5f5f;
              }

              &.-fixed {
                border: 1px solid #b8d2ff;
                background-color: #c3d7ff;
                color: #163b7b;
              }

              &.-app {
                border: 1px solid #b8ffbe;
                background-color: #c3ffcf;
                color: #1d7b16;
              }
            }

            .el-button {
              padding: 2px 4px;
            }
          }

          .product-remove,
          .product-kitchen {
            text-align: center;
            cursor: pointer;
            color: #716ACA;

            .icon {
              padding: 0 3px;
              border: 1px solid transparent;
              border-radius: 6px;

              &:hover {
                border-color: #DDD;
              }
            }

            &.-disabled {
              color: #DDD;
              cursor: not-allowed;
              pointer-events: none;
            }
          }

          .product-remove {
            color: #bf3415;
          }
        }
      }

      .container-footer {
        background-color: #fff7f7;
        padding: 0 7px 4px;
      }

      .products-total {
        font-size: 20px;
        font-weight: 500;
        color: #656565;
      }

      .order-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
        flex-wrap: wrap;

        .el-button {
          width: 49%;
          padding: 6px 5px;
          margin: 0;
        }

        .el-button:nth-child(3) {
          width: 100%;
          margin-top: 3px;
        }
      }

      .add-product {
        margin: 5px 0 10px;
      }
    }
  }
}

.table {
  width: 100%;
  max-width: 100%;
}

.table-client-data {
  .text {
    font-size: 18px;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #575757;
    }

    strong {
      display: inline-block;
      color: #FFF;
      padding: 4px 10px;
      border-radius: 6px;
    }

    .client-name-component {
      span {
        font-size: 18px;
      }
    }
  }

  .client .text strong {
    color: #333;
  }

  .debit .text strong {
    background-color: #b31b1b;
  }

  .balance .text strong {
    background-color: #1dac62;
  }

  .use-balance {
    margin-top: 10px;
  }
}

.kitchen-products {
  display: flex;

  h3 {
    margin-bottom: 10px !important;
  }

  .product-item {
    padding: 10px 20px !important;
    margin: 0 5px 10px !important;

    p {
      border-bottom: 1px solid #DDD;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0 !important;

      &:last-child {
        border-bottom: none;
      }

      .el-button {
        padding: 3px 5px !important;
        margin: 0 0 0 8px;
      }
    }

    .el-button {
      margin-top: 10px;
    }
  }
}

.select-product-search {
  .el-input {
    min-width: 400px;
  }
}

.select-products-list {
  display: flex;
  flex-wrap: wrap;

  .list-item {
    margin: 10px 6px;
    flex: 0 0 15%;
    height: 45vh;
    overflow-x: hidden;
    background-color: #f7f7f7;
    border-radius: 4px;
  }
}

.select-products {
  overflow: hidden;
  margin-bottom: 25px;

  .product-category-name {
    display: block;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px;
    padding: 3px 0;
    color: #4F4894;
    border-radius: 6px 6px 0 0;

    &.-bg {
      background-color: #4F4894;
      color: #FFF;
    }
  }

  .product-item {
    border-radius: 4px;
    border: 1px solid #c9c9c9;
    padding: 2px 8px;
    text-align: center;
    margin: 0 0 1px;
    cursor: pointer;

    &.-splitted {
      border: 1px solid #e0e0e0;
      background-color: #f9f9ff;
    }

    h3, p {
      margin: 0;
      padding: 0;
    }

    h3 {
      font-size: 16px;
      line-height: 18px;

      &.-big {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      color: #818181;
    }

    &.is-selected {
      border-color: #8D88D5;
      background-color: #7c7b89;

      .product-content * {
        color: #FFF;
      }
    }
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    border: 1px solid #eaeaea;
  }

  th {
    padding: 1px 6px;
    font-size: 12px;
  }

  td {
    padding: 6px;
  }

  tfoot th {
    font-size: 17px;
    padding: 4px 8px;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balance-to-use th {
  font-size: 16px !important;
}

.finish-order-dialog {
  .v-card__body {
    .table tfoot th {
      padding: 4px 8px;
    }
  }
}

.quantity-dialog {
  .v-card__text {
    padding: 20px !important;

    p {
      margin: 0;
      padding-bottom: 10px;
    }

    .el-input-number.-bigger {
      line-height: 58px;

      .el-input__inner {
        font-size: 40px;
        height: 60px;
        line-height: 60px;
      }
    }
  }
}

@media all and (max-width: 1400px) {
  .select-products-list {
    .list-item {
      flex: 0 0 18%;
    }
  }
}

@media all and (max-width: 1200px) {
  .select-products-list {
    .list-item {
      flex: 0 0 17%;
    }
  }
}

@media all and (max-width: 1000px) {
  .select-products-list {
    .list-item {
      flex: 0 0 23%;
    }
  }
}

@media all and (max-width: 700px) {
  .select-products-list {
    .list-item {
      flex: 0 0 31%;
    }
  }
}

@media all and (max-width: 550px) {
  .select-products-list {
    .list-item {
      flex: 0 0 46%;
    }
  }
}

@media all and (max-width: 980px) {
  .entry-daily-content {
    .el-card-daily {
      width: 31% !important;
    }
  }
  .select-products .product-item h3 {
    font-size: 16px;
  }

  .select-product-search {
    .el-input {
      min-width: auto;
    }
  }
}

@media all and (max-width: 580px) {
  .entry-daily-content {
    .el-card-daily {
      width: 47% !important;
    }
  }
  .payment-values {
    .el-col {
      width: 100%;
      display: block;
    }
  }
  .quantity-dialog {
    .v-card__text {
      .product-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      p {
        padding-bottom: 0;
      }
    }
  }
}
</style>
