<script>
import {mapGetters} from 'vuex'
import * as notifyService from '@app/services/notifyService'
import {defaultTenantStonePayment, getTenantSettings, saveTenantSettings} from '../../../service'
import {warningDialog} from '@utils/flash'

import {required} from 'vuelidate/lib/validators'

const SETTINGS_TYPE = 'tenant_stone_payment'

export default {
  name: 'stone-payment',
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      form: {...defaultTenantStonePayment},
      stoneConnectDevice: {
        showForm: false,
        data: {
          name: '',
          sn: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasProfessionalPlan', 'hasCommercialReceiptModule']),
    isCountryBrazil() {
      return (this.tenant?.country_id || 1 === 1)
    },
    stoneConnectDevices() {
      return this.form?.devices || []
    },
  },
  watch: {
    'form.enabled': function () {
      if (!this.isFirstLoad) {
        this.onSaveSettings()
      }
    },
    'form.auto_generate_invoice': function () {
      if (!this.isFirstLoad) {
        this.onSaveSettings()
      }
    }
  },
  validations: {
    stoneConnectDevice: {
      data: {
        name: {required},
        sn: {required},
      }
    }
  },
  async mounted() {
    this.isComponentLoading = true
    this.getSettings()
  },
  methods: {
    async getSettings() {
      const result = await getTenantSettings(SETTINGS_TYPE)
      this.form = {...this.form, ...result.data}
      setTimeout(() => {
        this.isFirstLoad = false
        this.isComponentLoading = false
      }, 200)
    },
    onSaveSettings() {
      this.isComponentLoading = true
      saveTenantSettings({type: SETTINGS_TYPE, settings: {...this.form}})
          .then((settings) => {
            notifyService.success()
            this.form = {...this.form, ...settings.data}
            this.isComponentLoading = false
          })
          .catch(e => {
            this.form.requested = false
            this.isComponentLoading = false
            console.log(e)
          })
    },
    handleStoneDevice(add) {
      if (!this.form.active || !this.form.enabled) {
        return
      }
      add = add || false
      if (add) {
        this.stoneConnectDevice.data.name = ''
        this.stoneConnectDevice.data.sn = ''
      }
      this.stoneConnectDevice.showForm = add
    },
    async onAddStoneDevice() {
      const {name, sn} = this.stoneConnectDevice.data
      if (this.form.devices.some(item => item.sn === sn)) {
        warningDialog({
          title: 'Já existe uma máquina cadastrada com esse Número de Série',
        })
      } else {
        this.form.devices.push({name, sn})
        await this.onSaveSettings()
        this.handleStoneDevice()
      }
    },
    async deleteStoneDevice(index) {
      this.form.devices.splice(index, 1)
      await this.onSaveSettings()
      this.handleStoneDevice()
    },
    async requestStoneConnect() {
      this.form.requested = true
      await this.onSaveSettings()
    }
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados..."
           v-if="isCountryBrazil">
    <div slot="header">
      <div>
        <span>TEF Stone - POS Smart Connect</span>
        <el-tag type="success" class="ml-1" size="mini" effect="dark"
                v-if="form.active && form.enabled">Ativo
        </el-tag>
      </div>
      <div v-if="!hasProfessionalPlan">
        <el-alert type="error"
                  show-icon
                  :closable="false"
                  class="ml-1"
                  center>
          <div slot="title" class="text-center">
            Disponível no plano de gestão profissional
          </div>
        </el-alert>
      </div>
    </div>
    <div :class="{'disabled': !hasProfessionalPlan}">
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item label="Código Stone" size="small">
            <el-input v-model="form.stoneCode"
                      :readonly="(form.active && form.requested) || form.enabled"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="el-button-form-align">
            <el-button type="success"
                       size="small"
                       v-if="!form.active && !form.requested"
                       :disabled="isComponentLoading || !form.stoneCode"
                       @click="requestStoneConnect">
              <i class="fas fa-check"></i>
              <span class="ml-1">Solicitar Integração</span>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-alert type="warning"
                show-icon
                :closable="false"
                center
                v-if="form.requested && !form.enabled">
        <div slot="title" class="text-center">
          <div>A integração foi solicitada e nossa equipe já estará iniciando o processo de liberação.</div>
          <div>Quando o processo foi concluído você será notificado para iniciar o cadastro das máquinas POS!
          </div>
        </div>
      </el-alert>
      <el-row :gutter="20" v-if="form.active">
        <el-col :span="12">
          <el-form-item label="Habilitar Pagamento via Máquina POS">
            <el-switch v-model="form.enabled"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="hasCommercialReceiptModule">
          <el-form-item label="Impressão de NFCe na Máquina">
            <el-switch v-model="form.auto_generate_invoice"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="form.enabled">
        <template v-if="stoneConnectDevice.showForm">
          <h3 class="mb-1">Nova Máquina</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Nome">
                <el-input v-model="stoneConnectDevice.data.name"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Número de Série">
                <el-input v-model="stoneConnectDevice.data.sn"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="success"
                     :disabled="isComponentLoading || $v.stoneConnectDevice.data.$invalid"
                     @click="onAddStoneDevice">
            <i class="fas fa-check"></i>
            <span class="ml-1">Salvar Dados</span>
          </el-button>
          <el-button type="default"
                     @click="handleStoneDevice(false)">
            <i class="fas fa-times"></i>
            <span class="ml-1">Cancelar</span>
          </el-button>
        </template>
        <div class="mt-3">
          <div class="flex-c mb-1">
            <h3 class="mb-1">Máquinas Habilitadas</h3>
            <el-button size="mini"
                       type="warning"
                       :disabled="!form.enabled"
                       @click="handleStoneDevice(true)">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Inserir Nova Máquina</span>
            </el-button>
          </div>
          <div class="table-responsive" v-if="stoneConnectDevices.length">
            <table class="custom-table-list">
              <thead>
              <tr>
                <th width="30%">Nome</th>
                <th>Número de Série</th>
                <th class="btn-actions">Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(device, index) in stoneConnectDevices" :key="index">
                <td>
                  {{ device.name }}
                </td>
                <td>
                  {{ device.sn }}
                </td>
                <td>
                  <div class="btn-actions">
                    <el-button type="danger"
                               size="mini"
                               title="Remover Máquina"
                               @click="deleteStoneDevice(index)">
                      <i class="fas fa-trash"></i>
                    </el-button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
  </el-card>
</template>
